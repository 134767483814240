
.contacts-container {
	width: 100%;
	background-color: var(--primary);
	padding-top: 8vh;
	padding-bottom: 8vh;
}
.contacts-container >h3 {
	margin-top: 0;
}

.contacts__wrapper {
	margin: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 5vw;
}

.contacts-link, .contacts-mailto {
	background-color: var(--text-background);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: start;
	padding: 10px;
	font-size: 1.2em;
	min-height: 32vh;
	width: 70%;
	gap: 2vh;
}

.contacts-link >p {
	text-align: center;
}
.mailto__wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 2vh;
}
.tg-bot__img, .contacts__img {
	height: 10vh;
	margin:  0;
	cursor: pointer;
}

.contacts-link h4, .contacts-mailto h4, .contact h4 {
	color: var(--tint);
}
