

.cards {
    padding: .5rem;
    background: var(--primary);
}

.cards__header {
    text-align: center;
    color: var(--tint);
	font-weight: bold;
	font-size: 2.4em;
	text-transform: uppercase;
}

.cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
}

.cards__wrapper {
    position: relative;
}

.cards__items {
    margin-bottom: 24px;
}

.cards__item {
    display: flex;
    flex: 1;
    margin: 0 1rem;
    border-radius: 10px;
    background-color: var(--accent);
}

.cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
}

.cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
}

.fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
}

.cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 4px 8px;
    max-width: calc((100%) - 60px);
    font-size: 14px;
    font-weight: 700;
    color: var(--tint);
    background-color: var(--background);
    border-radius: 8px 8px 0 0;
    border: 1px solid var(--tint);
    border-bottom: none;
}

.cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
}

.cards__item__img:hover {
    transform: scale(1.1);
}

.cards__item__info {
    padding: 20px 30px 30px;
}

.cards__item__text {
    color: var(--primary);
    font-size: 18px;
    line-height: 24px;
}

@media only screen and (max-width: 1120px) {
    .content__blog__container {
        width: 84%;
    }
}

@media only screen and (min-width: 1024px) {
    .cards__items {
        display: flex;
    }
}

@media only screen and (max-width: 1024px) {
    .cards__item {
        margin-bottom: 2rem;
    }
}

@media only screen and (max-width: 768px) {
    .cards {
        padding-bottom: 1rem;
    }
    .cards__item__text {
        font-size: 16px;
    }
}
