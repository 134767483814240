:root {
    --text-background: #F9FBE9;
	--banner: rgb(248, 183, 5);
	--banner-close: #fab730;
	--banner-glow: #f09;
}

/* @keyframes pulsate {
	100% { */
	  /* Larger blur radius */
	  /* text-shadow:
		0 0 4px #fff,
		0 0 12px #fff,
		0 0 21px #fff,
		0 0 42px var(--banner-glow),
		0 0 84px var(--banner-glow),
		0 0 96px var(--banner-glow),
		0 0 110px var(--banner-glow),
		0 0 160px var(--banner-glow);
	} */
   /* 0% {
	  /* A slightly smaller blur radius */
	  /* text-shadow:
		0 0 3px #fff,
		0 0 9px #fff,
		0 0 17px #fff,
		0 0 36px var(--banner-glow),
		0 0 70px var(--banner-glow),
		0 0 76px var(--banner-glow),
		0 0 90px var(--banner-glow),
		0 0 130px var(--banner-glow);
	}
  }  */

.adv-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 2vh;
	align-items: center;
	font-size: 1.3em;
	background-color:#010a01;
	padding: 4vh 0 0 0;
}

.adv-wrapper h3 {
	font-family: 'Ubuntu', sans-serif;
	color: var(--primary);
	font-size: 20px;
	margin: 0;
}

.adv-wrapper p {
	color: var(--primary);
	padding: 2vh 2vw;
	text-align: center;
}

.banner {
	margin: 4vh 2vw 7vh 2vw;
	border: 0.2rem solid #fff;
	border-radius: 2rem;
	padding: 1em;
	box-shadow: 0 0 .2rem var(--banner),
				0 0 .2rem var(--banner),
				0 0 2rem var(--banner),
				0 0 0.8rem var(--banner),
				0 0 2.8rem var(--banner),
				inset 0 0 1.3rem var(--banner);
	text-align: center;
	text-shadow: 
		0 0 7px var(--banner-close),
		0 0 10px var(--banner-close),
		0 0 21px var(--banner-close),
		0 0 42px var(--banner),
		0 0 82px var(--banner);
  } 

.bigger-text {
	font-family: 'Oswald', sans-serif;
	font-size: 1.2em;
	font-weight: 600;
	letter-spacing: 1px;
	color: var(--primary);
	text-transform: uppercase;
	/* animation: pulsate 0.8s ease-in-out infinite alternate;  */
}
.adv-image {
	height: 22vh;
	width: 100%;
	margin-top: 8vh;
}
.adv-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
/* 
@media screen and (prefers-reduced-motion) { 
	h1 {
	  animation: none;
	}
  }  */
