:root {
	--backg: #c4e3f1;
}

.icons {
	background-color: var(--background);
	padding: 8vh 0;
}

.icons >h3 {
	text-align: center;
	color: var(--black);
	font-weight: bold;
	font-size: 2.4em;
	text-transform: uppercase;
	margin: 0;
}

.icon__container {
	display: flex;
	justify-content: space-evenly;
	gap: 3vw;
	margin-top: 7vh;
	padding: 0 2vw;
}

.icon__item {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.icon__item__img {
	height: 70px;
	margin-bottom: 2vh;
}

.icon__item__label {
	color: var(--black);
	font-weight: 700;
	font-size: 1em;
}


@media screen and (max-width: 630px) {
	.icon__item__img {
		height: 60px;
	}
}

@media screen and (max-width: 430px) {
    .icon__container {
        flex-direction: column;
		gap: 4vh;
    }
	.icon__item__img {
		height: 80px;
		margin: 0;
	}
}