.delivery {
	width: 100%;
	padding: 2vh 2vw;
	background: var(--primary);
}

.delivery >p {
	font-size: 1.2em;
	line-height: 1.6em;
}

.delivery h3 {
	color: var(--tint);
}

.delivery__container {
	display: flex;
	justify-content: space-between;
	align-items: start;
	gap: 1vw;
	padding: 8vh 0;
}

.delivery__item {
	display: flex;
	flex-direction: column;
	gap: 10px;
	font-size: .6em;
}

.delivery__item__img {
	width: 100%;
	max-width: 10vw;
	object-fit: contain;
}

@media screen and (max-width: 1120px) {
	.delivery {
        max-width: 960px;
    }
}
@media screen and (max-width: 630px) {
	.delivery__container {
		padding: 4vh 0;
	}
}