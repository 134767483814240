.contact {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--primary);
}

form {
	max-width: 600px;
	width: 100%;
	padding: 3vh 4vw;
	border-radius: 8px;
	box-shadow: 0 0 10px rgba(0, 0 , 0, .1);
	margin-bottom: 5vh;
}

.input-box {
	margin-top: 4vh;
}
.input-box .field {
	width: 100%;
	height: 5vh;
	background: transparent;
	border: 2px solid #ddd;
	outline: none;
	border-radius: 6px;
	padding: 2vh 1vw;
	font-size: 1em;
	margin-top: 0;
}
.field.mess {
	height: 16vh;
	resize: none;
}
input.input-check {
	margin: 3vh 15px 0 0;
}
a.input-p {
	color: var(--background);
}

.checkbox {
	font-size: 12px;
}
form button {
	width: 100%;
	margin-top: 2vh;
}

@media screen and (max-width: 630px) {
	form {
		max-width: 420px;
	}
}

@media screen and (max-width: 430px) {
	form {
		max-width: 260px;
	}
}