/* TODO */
/*
5. скорость загрузки
11. Добавить форму на страницу Заказа
23. сделать шрифты локально (ttf, woff2)


-========-
1. Сделать возможность регистрации на сайте 
2. Отправлять куки пользователям
3. Добавить раздел "Подпишись на нашу рассылку"
4. Добавить отдельную страницу "Для оптовых покупателей"
5. Добавить раздел отзывов
6. Добавить раздел "наш магазин на озон"
7. Внести green pato в Яндекс Бизнес
8. Добавить капчу на формы
9. Сделать обработку ошибок на формах
31. Добавить site map
30. Добавить страницу 404

*/

:root {
  --base-bg-color: #F1E9DA;
  --primary: #fff;
  --accent: #504842;
  --tint: #AB3541;
  --background: #B4C277;
  --text-background: #F9FBE9;
  --green-accent: #008000;
  --light-brown: #AD8945; 
  --black: black;
}


@font-face {
  font-family: 'Ubuntu';
  src: url('./../src/fonts/Ubuntu-Medium.woff2') format('woff2'),
      url('./../src/fonts/Ubuntu-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('./../src/fonts/Ubuntu-Bold.woff2') format('woff2'),
      url('./../src/fonts/Ubuntu-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('./../src/fonts/Ubuntu-Light.woff2') format('woff2'),
      url('./../src/fonts/Ubuntu-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('./../src/fonts/Ubuntu-Regular.woff2') format('woff2'),
      url('./../src/fonts/Ubuntu-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Oswald';
  src: url('./../src/fonts/Oswald-Regular.woff2') format('woff2'),
      url('./../src/fonts/Oswald-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 300;
  margin: 0 auto;
  max-width: 1200px;
  background-color: var(--base-bg-color);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  line-height: 1.5em;
  color: var(--accent);
}

button[disabled] {
  opacity: 0.5;
  pointer-events: none;
} 

h1 {
  font-family: 'Oswald', sans-serif;
  font-size: 52px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}

h2 {
  font-family: 'Oswald', sans-serif;
  font-size: 1.8em;
  font-weight: 600;
	text-transform: uppercase;
  text-align: center;
  margin: 4vh 0;
}

h3 {
  font-family: 'Oswald', sans-serif;
  color: var(--tint);
  text-align: center;
	font-weight: 600;
  font-size: 2.4em;
	text-transform: uppercase;
  margin: 8vh 0;
}

h4 {
  font-family: 'Oswald', sans-serif;
  font-size: 24px;
  font-weight: 700;
  color: var(--black);
  text-transform: uppercase;
  text-align: center;
  margin: 4vh 0 0 0;
} 

h5{
  font-family: 'Oswald';
}

h6 {
  font-size: 12px;
  font-weight: 700;
  color: var(--tint);
  text-align: center;
  text-transform: uppercase;
  margin: 2vh 0;
}

ol, ul {
  list-style-type: none;
}

.divider {
	width: 2px;
	height: 25vh;
	border-right: 3px solid darkgrey;
}
.content {
  margin: 0 auto;
  margin-top: 10vh;
  padding: 0 5vw;
}

.content >h2 {
  font-size: 2.4em;
}

.scheme {
  height: 50vh;
  margin: 3vh 0;
}

.license {
  font-size: 0.6em;
  color: lightgray;
  position: absolute;
  bottom: 5px;
  left: 10px;
}

/*PAGES*/
.home,
.garlic-header,
.onion-header,
.order-header{
  display: flex;
  flex-direction: column;
  height: 90vh;
  align-items: center;
  justify-content: start;
  font-size: 1em;
}


/* GARLIC */
.garlic {
  background-color: var(--primary);
}
.garlic-header, .onion-header, .order-header{
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: var(--accent);
}
.garlic-header {
  background-image: url('./images/garlic/garlic-bckg.webp');
  max-height: 60vh;
}
.garlic-header>h1, .onion-header >h1, .order-header>h1 {
  font-size: 5em;
  text-transform: uppercase;
  color: var(--primary);
  margin-top: 20vh;
}
.content>h3 {
  text-align-last: left;
  text-transform: none;
  color: var(--tint);
	font-weight: 700;
  font-size: 18px;
  margin: 3vh 0 0 0;
} 
.garlic-assort, .onion-assort {
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 2vw;
  margin: 5vh 0;
}
.garlic-assort {
  justify-content: space-between;
}
.garlic__type-item, .onion__type-item{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 30%;
  margin: 2vh 2vw;
}
.garlic__type-item img, .onion__type-item img {
  height: 20vh;
  object-fit: contain;
  margin-bottom: 4vh;
  border-radius: 8px;
}
.garlic__type-item h5, .onion__type-item h5 {
  color: var(--tint);
  font-size: .8em;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
}
.garlic__type-img {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  height: 100%;
}
.garlic-price {
  margin-top: 1vh;
}
.garlic-price >h4 {
  color: var(--tint);
  font-size: 1.1em;
}
.garlic-price >p {
  line-height: 1.2em;
  font-size: 1em;
}
.garlic-price >p.garlic-size {
  font-size: .8em;
}
.garlic-cat {
  margin-top: 12px;
}
.garlic-sorts, .onion-sorts {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: row;
  gap: 2vh 3vw;
}
.garlic__sort-item, .onion__sort-item{
  list-style-type: none;
  background-color: var(--background);
  border-radius: 12px;
  padding: 20px;
  font-size: .9em;
  color: var(--accent);
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
}
.garlic__sort-wrap, .onion__sort-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.garlic__sort-wrap img, .onion__sort-wrap img {
  height: 20vh;
  border-radius: 50%;
}
.garlic__sort-wrap {
  position: relative;
}
.garlic__sort-wrap::after {
  content: attr(data-category);
  position: absolute;
  top: 0;
  left: 0;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: var(--primary);
  background-color: var(--tint);
  box-sizing: border-box;
  z-index: 3;
  border-radius: 8px;
}
.garlic__sort-info >h5, .onion__sort-info >h5{
  color: var(--tint);
  font-weight: 700;
  font-size: 1.5em;
  text-transform: uppercase;
  margin: 2vh 0;
}
.garlic__sort-info >p {
  line-height: 1.6em;
  font-size: 1.1em;
}

/*ONION*/
/* некоторые css свойства в разделе Garlic*/
.onion {
  background-color: var(--primary);
}
.onion-header {
  background-image: url('./images/onion/onion-bckg.webp');
  max-height: 60vh;
}
.order-header>h1 {
  margin-top: 10vh;
}
.onion-assort h3 {
  font-size: 2em;
  text-transform: uppercase;
  color: var(--tint);
  text-align: center;
}
.onion-assort .divider {
  height: 35vh;
}
.onion-assort-shalot, .onion-assort-rep {
  display: flex;
  align-items: stretch;
  justify-content: center;
}
.onion__sort-info >ul {
  list-style-type: none;
  margin-top: 3vh;
  display: flex;
  flex-direction: column;
}
.onion-sorts {
  margin-bottom: 10vh;
}
.onion__sort-item {
  display: flex;
  flex-direction: column;
  justify-content: start;
}
.onion__sort-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.onion__sort-descr>h5 {
  color: var(--tint);
  font-size: 1.4em;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 2vh;
}
.onion-li {
  height: 20px;
  border-bottom: 1px dotted var(--accent);
  background-color: var(--background);
  margin-top: 10px;
}
.onion-price-line {
  float: left;
  background-color: var(--background);
  font-weight: bold;
}
.onion-price {
  float: right;
  background-color: var(--background);
}
.onion-comment {
  display: flex;
  justify-content: start;
}
.onion-bold {
  font-size: 1.2em;
  color: var(--accent);
  line-height: 2em;
  font-weight: bold;
}
.coming {
  opacity: .6;
  padding-bottom: 10vh;
}
/*ORDER PAGE*/
.order {
  background-color: var(--primary);
}
.order-header {
  background-image: url('./images/order-bckg.webp');
  max-height: 50vh;
  position: relative;
}
.order-header>h1 {
  font-size: 4em;
}
.order-list {
  width: fit-content;
  margin: 0 auto;
  list-style-type: none;
  font-family: 'Oswald';
  font-size: 1.4em;
  font-weight: bold;
  text-transform: uppercase;
}
.order-list >li {
  line-height: 2em;
}
.order__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 6vh;
  padding-bottom: 6vh;;
  max-width: 100%;
}
.order__item {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 60vw;
  margin-top: 5vh;
}
.order__item img {
  margin: 0;
}
.order__item label {
  font-size: 1.4em;
}

/* POLICY */
.policy {
  padding: 5vh 3vw;
  background-color: var(--primary);
}
.policy-header {
  color: var(--tint);
  font-size: 2em;
  margin-bottom: 5vh;
}
.policy-subheader {
  font-weight: 700;
  margin-bottom: 1vh;
  line-height: 1em;
}
.policy-link {
  color: rgb(62, 206, 246);
  display: flex;
  justify-content: center;
  font-size: 0.8em;
  margin-top: 2vh;
  text-decoration: none;
}

/* Button-like links */
.effect {
  text-align: center;
  display: inline-block;
  position: relative;
  text-decoration: none;
  color: var(--primary);
  text-transform: capitalize;
  background-color: var(--tint);
  font-family: 'Oswald', sans-serif;
  font-size: 24px;
  font-weight: 600;
  padding: 14px 34px;
  border-radius: 8px;
  overflow: hidden;
}

.effect.effect-5 {
  transition: all 0.2s linear 0s;
  
  &:before {
    content: "\f054";
    font-family: FontAwesome;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0px;
    height: 100%;
    width: 30px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 0 50% 50% 0;
    transform: scale(0,1);
    transform-origin: left center;
    transition: all 0.2s linear 0s;
  }
  
   &:hover {
    text-indent: 30px;
    
    &:before {
      transform: scale(1,1);
      text-indent: 0;
    }
  }
}

/*MEDIA*/
@media screen and (max-width: 960px) {
  body{
    font-size: .8em;
  }
}

@media screen and (max-width: 768px) {
  body{
    font-size: .7em;
  }
  .onion-li:last-child {
    border: none;
  }
  .onion-assort {
    flex-direction: column;
  }
  .onion-assort h3 {
    margin-bottom: 20px;
  }
  .onion__scheme-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .onion-assort .divider {
    display: none;
  }
}

@media screen and (max-width: 620px) {
  .content {
    padding: 3vh 2vw;
  }
  .garlic__sort-item {
    padding: 10px;
  }
  .garlic-header>h1, .onion-header>h1 {
    font-size: 4em;
  }
  .garlic__type-item {
    margin: 0;
  }
  .garlic__type-item img {
    height: 15vh;
  }
  .order-header>h1 {
    font-size: 3em;
  }
  .order__item {
    width: 80%;
    justify-content: space-between;
  }
  .garlic-sorts, .onion-sorts {
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: repeat(1, 1fr);
    gap: 4vh 3vw;
  }
  .garlic__sort-wrap::after  {
    top: 12px;
  }
  .onion-assort-shalot, .onion-assort-rep {
    display: flex;
    justify-content: space-around;
  }
}

@media screen and (max-width: 500px) {
  .garlic-assort {
    flex-direction: column;
    align-items: center;
    gap: 3vh;
  }
  .garlic__type-item {
    width: 80%;
    border: 1px solid lightgray;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0 , 0, .1);
  }
  .garlic__type-item img {
    height: 20vh;
    margin-bottom: 2vh;
  }
  .garlic-cat-size {
    display: flex;
    gap: 5vw;
  }
  .garlic-cat {
    margin: 0;
  }
  .garlic-size {
    font-size: .8em;
    line-height: 2em;
  }
}

@media screen and (max-width: 430px) {
  .garlic-cat-size {
    gap: 2vw;
  }
  .garlic__type-item {
    padding: 10px;
    width: 100%;
  }
}





