.footer-container {
    background-color: var(--accent);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--primary);
}

.footer-links {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 5vh;
}

.footer-link-wrapper {
    display: flex;
}

.footer-link-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1vh;
    margin: 0 3vw;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
    font-size: 1em;
}

.footer-link-items:last-child {
    align-items: center;
    justify-content: start;
}

.footer-link-items:last-child a:focus:hover {
    border-bottom: 2px solid transparent
}

.footer-link-items h4 {
    color: var(--primary);
    margin-bottom: 16px;
    font-size: 1.2em;
    margin: 0;
    margin-bottom: 2vh;
    font-weight: 700;
}

.footer-link-items a {
    color: #fff;
    text-decoration: none;
    border-bottom: 2px solid transparent;
}
.footer-link-items:first-child span {
    color: var(--primary);
    font-size: .8em;
}

.footer-link-items a:focus:hover {
    color: #e9e9e9;
    transition: 0.3s ease-out;
    border-bottom: 2px solid var(--background);
}

.footer-link-items img {
    height: 5vh;
}
.footer-link-order {
    display: none;
}

/*DISCLAIMER*/
.disclaimer {
    margin-bottom: 3vh;
}
.disclaimer p {
    color: var(--primary);
    text-align: center;
    font-size: 0.8em;
}

.divider-hor {
    width: 30%;
    height: 1px;
    border-bottom: 1px solid var(--primary);
    margin: 2vh auto 3vh;
}

/* Social Icons */
.social-media {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 2vh 0 5vh 0;
}

.social-media a {
    font-size: 1.4em;
    font-weight: bold;
}

.social-media p{
    color: var(--primary);
}
.social-logo-up {
    display: none;
}
.react {
    display: flex;
    gap: .5vw;
}
.react i {
    color: rgb(62, 206, 246);
    font-size: 24px;
}

.react p {
    line-height: 24px;
}
.social-logo, .social-logo-up {
    color: var(--green-accent);
    text-shadow: 1px 1px 2px #fff;
    font-family: 'Ubuntu';
    font-weight: 700;
    letter-spacing: .2em;
    text-transform: lowercase;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.4em;
}

.website-rights {
    color: var(--primary);
}

.copyright {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
/* @media screen and (max-width: 1120px) {
	.footer-container{
        max-width: 960px;
    }
} */

@media screen and (max-width: 630px) {
    .footer-container {
        padding: 2rem 0 1rem 0;
    }
    .footer-link-items {
        width: 100px;
        font-size: 11px;
    }
    .social-media img {
        width: 60px;
    }
    .social-logo img {
        display: none;
    }
    .footer-link-items:last-child {
        display: none;
    }
    .footer-link-order {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
        gap: 3vw;
        margin-bottom: 3vh;
    }
    .footer-link-order h4 {
        color: var(--background);
        font-size: 1.4em;
        font-weight: 700;
        margin: 1vh 0;
    }
    .footer-link-order img {
        height: 8vh;
    }
}

@media screen and (max-width: 430px) {
    .social-media a {
        display: none;
    }
    .social-logo-up {
        display: inline-block;
    }
}