
.social-container {
	background-color: var(--background);
	color: var(--accent);
	font-size: 1.2em;
	padding: 5vh 2vw;
}

.social-container h4 {
	font-size: 1.5em;
	color: var(--black);
	margin-bottom: 4vh;
}
.social__wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5vw;
}
.social__list {
	list-style-type: none;
	line-height: 1.8em;
}

.social__img{
	height: 20vh;
}

@media screen and (max-width: 630px) {
	.social__img{
		height: 12vh;
	}
}