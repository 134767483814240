
.btn {
    padding: 8px 20px;
    border-radius: 8px;
    outline: none;
    border: none;
    cursor: pointer;
    font-family: 'Oswald', sans-serif;
    font-weight: 600;
    letter-spacing: .08em;
}

/*btn color*/
.btn--primary {
    background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary);
}

.btn--outline  {
    background-color: var(--tint);
    color: var(--primary);
    padding: 8px 20px;
    border: 3px solid var(--tint);
    transition: all 0.3s ease-out;
}

/*btn size*/
.btn--medium {
    padding: 10px 20px;
    font-size: 18px;
}

.btn--large {
    padding: 14px 34px;
    font-size: 24px;
}

/*hover effects*/
.btn--medium:focus:hover {
    background-color: var(--primary);
    color: var(--tint);
    transition: all 0.3s ease-out;
}

.btn--large:focus:hover {
    background-color: var(--primary);
    border-color: var(--tint);
    color: var(--tint);
    transition: all 0.3s ease-out;
}

@media screen and (max-width: 960px) {
    .btn--large {
        padding: 10px 22px;
        font-size: 18px;
    } 
}