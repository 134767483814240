/* video {
    object-fit: cover;
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: -1;
} */

.hero-container {
    /* background: linear-gradient(rgba(245, 242, 242, .6), rgba(249, 247, 247, 0.2)), url('../images/main-bckg.webp') center center / cover no-repeat; */
    background-color: var(--text-background);
    position: relative;
    min-height: 60vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.hero-btns {
    margin-top: 32px;
}

.hero-btns .btn{
    margin: 6vh 2vw;
}

.hero-banner-wrapper .hero-banner{
    width: 160px; 
    height: 160px;
    background-color: var(--primary);
    border-radius: 50%;
    position: absolute;
    top: 60%;
    right: 18%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px dotted var(--tint);
    box-shadow: 5px 5px 5px 5px rgba(79, 76, 76, 0.6); 
}

.hero-banner-wrapper .hero-banner h3{
    color: var(--green-accent);
    font-family: 'Ubuntu', sans-serif;
    letter-spacing: 1px;
    font-weight: 500;
    transform: rotateZ(-40deg);
    font-size: 1.1em;
}

.hero-banner-wrapper .hero-banner h3 >span {
    color: var(--tint);
    font-family: 'Oswald';
    letter-spacing: 2px;
    font-size: 2em;
}

/* .fa-play-circle {
    margin-left: 4px;
} */


@media screen and (max-width: 960px) {
    .hero-container > h1 {
        font-size: 32px;
    }
    .hero-container > p {
        font-size: 22px;
    }
    .hero-btns {
        margin-top: 10px;
    }
    .hero-container {
        height: 70vh;
    }
    .hero-banner-wrapper .hero-banner {
        width: 130px; 
        height: 130px;
    }
}

@media screen and (max-width: 768px) {
    .hero-container > h1 {
        font-size: 32px;
    }
    .hero-container > p {
        font-size: 22px;
    }
    .btn-mobile {
        display: block;
        text-decoration: none;
    }
    .btn {
        width: 100%;
    }
    .hero-banner-wrapper .hero-banner {
        right: 12%;
    }
    .hero-banner-wrapper .hero-banner {
        top: 70%;
    }
}

@media screen and (max-width: 630px) {
    .hero-container h1 {
        font-size: 28px;
        text-align: center;
        margin-top: 10vh;
    }
    .hero-container  h3 {
        font-size: 18px;
        text-align: center;
    }
    .hero-banner-wrapper .hero-banner {
        width: 100px; 
        height: 100px;
        top: 10%;
    }
    .hero-banner-wrapper .hero-banner h3 {
        letter-spacing: 1px;
        font-size: 0.9em;
    }
}
@media screen and (max-width: 430px) {
    .hero-banner-wrapper .hero-banner {
        right: 40px;
        top: 20px;
    }
}
